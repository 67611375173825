import { Box, Container, Grid, Typography } from "@mui/material";
import img1 from '../assets/images/h3-icon-img1.png';
import img2 from '../assets/images/h3-icon-img2.png';
import img3 from '../assets/images/h3-icon-img3.png';

export const ThreeColSection = () => {
    return <Box color="black" padding={3} marginTop={5} marginBottom={6}>
        <Container>
            <Grid container spacing={6}>
                <Grid item md={4} style={{ backgroundImage: `url(${img1})`, backgroundRepeat: "no-repeat", backgroundPosition: "center" }}>
                    <Typography variant="h4" color="secondary" sx={{ mb: 4, mt: 14, fontFamily: "'Style Script', cursive" }}>Masajes Orientales</Typography>
                    <Typography variant="body1" color="white" lineHeight={2}>Nuestras expertas masajistas llevarán tu cuerpo y mente a un relax absoluto con sus técnicas orientales. Ven a descubrirlo por ti mismo</Typography>

                </Grid>
                <Grid item md={4} style={{ backgroundImage: `url(${img2})`, backgroundRepeat: "no-repeat", backgroundPosition: "center" }}>
                    <Typography variant="h4" color="secondary" sx={{ mb: 4, mt: 14, fontFamily: "'Style Script', cursive" }}>Modernas instalaciones</Typography>
                    <Typography variant="body1" color="white" lineHeight={2}>Disponemos de unas modernas y elegantes instalaciones, para lograr que tu experiencia sea lo más placentera posible desde que entres en nuestro centro</Typography>

                </Grid>
                <Grid item md={4} style={{ backgroundImage: `url(${img3})`, backgroundRepeat: "no-repeat", backgroundPosition: "center" }}>

                    <Typography variant="h4" color="secondary" sx={{ mb: 4, mt: 14, fontFamily: "'Style Script', cursive" }}>Cerca de ti</Typography>
                    <Typography variant="body1" color="white" lineHeight={2}>Nos encontrarás a 5 minutos caminando desde el Camp Nou. Cerca de la parada de metro de Collblanc</Typography>
                </Grid>
            </Grid>
        </Container>
    </Box>
}