import { Container, Grid, Link, Typography } from "@mui/material"


export const Contacto = () => {
    //<iframe width="600" height="450" style={{border:0, borderRadius: 25}} allowFullScreen={true} src="https://www.google.com/maps/embed/v1/place?q=Masajes%20Xiang%20Shou&key=AIzaSyD_nxRAAlP1LJ3H10idWiOWCGED1-QoVj8"></iframe>

    return <Container>
        <Typography variant="h3" color="white" sx={{mb:4}}>Contacto</Typography>
        <Typography variant="body1" sx={{mb:8}} color="white">Nos encontrarás muy cerca de ti, a tan solo 5 minutos caminando desde el Camp Nou</Typography>
        <Grid container spacing={8}>
            <Grid item xs={12} md={8}><iframe src="https://snazzymaps.com/embed/556537" width="100%" height="420px" style={{border:0, borderRadius: 25}} allowFullScreen={true}></iframe></Grid>
            <Grid item md={4} style={{textAlign: "start", color:"white"}}>
            <Typography variant="h4" color="secondary" sx={{ mt: 0, fontFamily: "'Style Script', cursive" }}>Horario</Typography>
            <pre>LUN-SAB     9:30-21:30<br />DOMINGO    10:30-20:30</pre>
            <Typography variant="h4" color="secondary" sx={{ mt: 4, fontFamily: "'Style Script', cursive" }}>Donde estamos</Typography>
            <pre>Carrer Doctor Marti Julia 46<br/>
            L'Hospitalet de Llobregat (Barcelona)<br/>
            <Link href="https://www.google.com/maps/dir/?api=1&destination=Xiang+Shou" target="_blank" color="secondary">Como llegar</Link></pre>
            <pre>Cerca del metro de Collblanc L5 y L9Sud</pre>
            <Typography variant="h4" color="secondary" sx={{ mt: 4, fontFamily: "'Style Script', cursive" }}>Teléfono</Typography>
            <pre>688 057 055 <Link href="tel:+34688057055" color="secondary">Llamar</Link> <Link href="https://wa.link/t1v1no" target="_blank" color="secondary">WhatsApp</Link></pre>
            </Grid>
        </Grid> 
    </Container>
}


