import React from 'react';
import logo from './logo.svg';
import './App.css';
import ElevateAppBar from './components/ElevatedAppBar';
import HeaderOne from './components/Header';
import { ThreeColSection } from './components/3ColSection';
import { TwoColSection } from './components/2ColSection';
import { TwoColSectionB } from './components/2ColSectionB';
import { Tratamientos } from './components/Tratamientos';
import { Galeria } from './components/Galeria';
import { Contacto } from './components/Contacto';
import { Instalaciones } from './components/Instalaciones';

function App() {
  return (
    <div className="App" style={{paddingBottom: "100px"}}>
      <ElevateAppBar>
        <HeaderOne></HeaderOne>
        <ThreeColSection></ThreeColSection>
        <Tratamientos></Tratamientos>
        <Instalaciones></Instalaciones>
        <Galeria></Galeria>
        <Contacto></Contacto>
      </ElevateAppBar>
    </div>
  );
}

export default App;
