import { useTheme } from "@emotion/react";
import { Container, ImageList, ImageListItem, Typography, useMediaQuery } from "@mui/material"


export const Instalaciones = () => {

    function srcset(image: string, size: number, rows = 1, cols = 1) {
        return {
            src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
            srcSet: `${image}?w=${size * cols}&h=${size * rows
                }&fit=crop&auto=format&dpr=2 2x`,
        };
    }

    const itemData = [
        {
            img: '/place/1.jpg',
        },
        {
            img: '/place/2.jpg',
        },
        {
            img: '/place/3.jpg',
        },
        {
            img: '/place/4.jpg',
        },
        {
            img: '/place/5.jpg',
        },
        {
            img: '/place/6.jpg',
        },
    ];

    return <Container  sx={{mb: 8}}>
        <Typography variant="h3" sx={{mb:4}} color="white">Instalaciones</Typography>
        <Typography variant="body1" sx={{mb:8}} color="white">Nuestras instalaciones te reciben con un ambiente cálido y acogedor, creando el escenario perfecto para tu experiencia de relajación. Desde la iluminación tenue hasta la cuidada decoración, cada detalle ha sido pensado para ofrecerte un oasis de tranquilidad. Disfruta de la comodidad de nuestras salas privadas y deja que la atmósfera envolvente te lleve a un viaje de total relajación</Typography>
        <ImageList variant="masonry" sx={{
    columnCount: {
      xs: '2 !important',
      sm: '2 !important',
      md: '3 !important',
      lg: '4 !important'
    },
  }} gap={8}>
        {itemData.map((item) => (
            <ImageListItem key={item.img}>
                <img
                    srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                    src={`${item.img}?w=248&fit=crop&auto=format`}
                    alt="Instalaciones"
                    style={{borderRadius: 12}}
                    loading="lazy"
                />
            </ImageListItem>
        ))}
    </ImageList></Container>
}