import { Button, Grid, Typography } from "@mui/material"
import Container from "@mui/material/Container"
import aceites from "../assets/images/h3-img1.jpg"
import relajante from "../assets/images/h3-img2.jpg"
import manos from "../assets/images/4manos.jpg"
import thai from "../assets/images/thai2.jpg"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faClock, faClockFour } from "@fortawesome/free-solid-svg-icons"
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons"

export const Tratamientos = () => {
    return <section id="tratamientos"><Container sx={{ mb: 8 }}>
        <Typography variant="h3" sx={{ mb: 4 }} color="white">Tratamientos</Typography>
        <Typography variant="body1" sx={{ mb: 8 }} color="white">Descubre una variedad de tratamientos cuidadosamente seleccionados, desde masajes terapéuticos que alivian los músculos hasta técnicas especializadas que revitalizan tu energía interior. En nuestro oasis de tranquilidad, cada tratamiento está diseñado para proporcionarte un escape rejuvenecedor, restaurando tu equilibrio y promoviendo una sensación de total bienestar. <strong>Conoce la magia de nuestros masajes</strong> y sumérgete en un mundo donde el cuidado personal es una prioridad. Tu relajación y revitalización comienzan aquí</Typography>
        <Grid container spacing={3} sx={{ mb: 4 }}>
            <Grid item xs={6} md={3}>
                <img src={relajante} style={{ borderRadius: 20, width: "100%", height: "360px", objectFit: "cover" }} alt="Masaje Relajante"></img>
                <Typography variant="h3" color="secondary" sx={{ mb: 1, mt: -2, fontFamily: "'Style Script', cursive" }}>Relajante</Typography>
                <Typography variant="body1" color="white"><pre><FontAwesomeIcon size="xs" color="white" icon={faClockFour} />60m     35€</pre></Typography>
                <Typography variant="body1" color="white"><pre><FontAwesomeIcon size="xs" color="white" icon={faClockFour} />30m     25€</pre></Typography>
            </Grid>
            <Grid item xs={6} md={3}>
                <img src={aceites} style={{ borderRadius: 20, width: "100%", height: "360px", objectFit: "cover" }} alt="Masaje con aceites esenciales"></img>
                <Typography variant="h3" color="secondary" sx={{ mb: 1, mt: -2, fontFamily: "'Style Script', cursive" }}>Aceites Esenciales</Typography>
                <Typography variant="body1" color="white"><pre><FontAwesomeIcon size="xs" color="white" icon={faClockFour} />60m     40€</pre></Typography>
                <Typography variant="body1" color="white"><pre><FontAwesomeIcon size="xs" color="white" icon={faClockFour} />30m     30€</pre></Typography>
            </Grid>
            <Grid item xs={6} md={3}>
                <img src={thai} style={{ borderRadius: 20, width: "100%", height: "360px", objectFit: "cover" }} alt="Masaje Thai"></img>
                <Typography variant="h3" color="secondary" sx={{ mb: 1, mt: -2, fontFamily: "'Style Script', cursive" }}>Thai</Typography>
                <Typography variant="body1" color="white"><pre><FontAwesomeIcon size="xs" color="white" icon={faClockFour} />60m     60€</pre></Typography>
                <Typography variant="body1" color="white"><pre><FontAwesomeIcon size="xs" color="white" icon={faClockFour} />30m     40€</pre></Typography>
            </Grid>
            <Grid item xs={6} md={3}>
                <img src={manos} style={{ borderRadius: 20, width: "100%", height: "360px", objectFit: "cover" }} alt="Masaje a 4 manos"></img>
                <Typography variant="h3" color="secondary" sx={{ mb: 1, mt: -2, fontFamily: "'Style Script', cursive" }}>4 Manos</Typography>
                <Typography variant="body1" color="white"><pre><FontAwesomeIcon size="xs" color="white" icon={faClockFour} />60m     70€</pre></Typography>
                <Typography variant="body1" color="white"><pre><FontAwesomeIcon size="xs" color="white" icon={faClockFour} />30m     50€</pre></Typography>
            </Grid>
        </Grid>
        <Button variant="outlined" color="secondary" href="https://wa.link/t1v1no" target="_BLANK" sx={{ mr: 2 }} startIcon={<FontAwesomeIcon icon={faWhatsapp}></FontAwesomeIcon>}>Reservar por whattsapp</Button>
        <Button variant="outlined" color="secondary" sx={{ display: "none" }}>Ver más...</Button>
    </Container>
    </section>
}
