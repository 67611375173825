import { useTheme } from "@emotion/react";
import { Container, ImageList, ImageListItem, Typography, useMediaQuery } from "@mui/material"


export const Galeria = () => {

    function srcset(image: string, size: number, rows = 1, cols = 1) {
        return {
            src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
            srcSet: `${image}?w=${size * cols}&h=${size * rows
                }&fit=crop&auto=format&dpr=2 2x`,
        };
    }

    const itemData = [
        {
            img: '/galeria/a.jpg',
            title: 'Bed',
        },
        {
            img: '/galeria/b.jpg',
            title: 'Books',
        },
        {
            img: '/galeria/c.jpg',
            title: 'Sink',
        },
        {
            img: '/galeria/d.jpg',
            title: 'Kitchen',
        },
        {
            img: '/galeria/e.jpg',
            title: 'Blinds',
        },
        {
            img: '/galeria/f.jpg',
            title: 'Chairs',
        },
        {
            img: '/galeria/g.jpg',
            title: 'Laptop',
        },
        {
            img: '/galeria/h.jpg',
            title: 'Doors',
        },
        {
            img: '/galeria/i.jpg',
            title: 'Coffee',
        },
        {
            img: '/galeria/k.jpg',
            title: 'Storage',
        },
        {
            img: '/galeria/l.jpg',
            title: 'Candle',
        },
        {
            img: '/galeria/m.jpg',
            title: 'Coffee table',
        },
    ];

    return <Container  sx={{mb: 8}}>
        <Typography variant="h3" sx={{mb:4}} color="white">Masajistas</Typography>
        <Typography variant="body1" sx={{mb:8}} color="white">Embárcate en un viaje de sensualidad y placer, donde la belleza de nuestras masajistas se une con su talento para el relax. Con movimientos exquisitos y una presencia cautivadora, te transportarán a un mundo de éxtasis sensorial. Déjate seducir por la fusión de su atractivo irresistible y su destreza experta, mientras te sumergen en un océano de pasión y relajación sin igual</Typography>
        <ImageList variant="masonry" sx={{
    columnCount: {
      xs: '2 !important',
      sm: '2 !important',
      md: '3 !important',
      lg: '4 !important'
    },
  }} gap={8}>
        {itemData.map((item) => (
            <ImageListItem key={item.img}>
                <img
                    srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                    src={`${item.img}?w=248&fit=crop&auto=format`}
                    alt={item.title}
                    style={{borderRadius: 12}}
                    loading="lazy"
                />
            </ImageListItem>
        ))}
    </ImageList></Container>
}